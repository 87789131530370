import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import React, { useState, useEffect } from 'react';
import {
  Button,
  Tooltip,
  PageHeader,
  Modal as ModalAntd,
  Row,
  Col,
  notification,
  message,
} from 'antd';
import './Consultation.scss';
import PermissionsAsigner from '../../components/PermissionsAsigner';
import { ROLES } from '../../utils/constants';
import moment from 'moment';
import { getAccessTokenApi } from '../../api/auth';
import {
  ListDates,
  ViewInfo,
  PageListAndView,
} from '../../components/ListAndDetail';
import Spin from '../../components/Spin';
import {
  deleteConsultationApi,
  getConsultationApi,
} from '../../api/consultation';
import ConsultationForm from '../../components/Ambulatory/ConsultationForm';
import { getNotePatientApi } from '../../api/patient';
import {
  DeleteOutlined,
  FilePdfOutlined,
  UnorderedListOutlined,
  FormOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  EditOutlined,
  CloseOutlined,
  CloseCircleFilled,
  PrinterOutlined,
  FileOutlined,
  FileAddOutlined,
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PatientDescription from '../../components/Ambulatory/PatientDescription';
import { PdfConsultation } from '../../components/PdfReports';
import { getSignatureApi } from '../../api/user';
import { getPrescriptionApi } from '../../api/prescription';
import { newPresciption } from '../../components/Prescriptions/NewPrescription';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Consultation(props) {
  const { patientId, patientName } = props.match.params;
  const query = useQuery();
  const turnId = query.get('turnId');
  const shiftId = query.get('shiftId');
  const turnDay = query.get('turnDay');

  const [data, setData] = useState(null);
  const [dataAll, setDataAll] = useState(null);
  const [consultations, setConsultations] = useState();
  const [reload, setReload] = useState(false);
  const [order, setOrder] = useState('desc');
  const [visible, setVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [note, setNote] = useState();
  const [patient, setPatient] = useState([]);
  const [newConsultation, setNewConsultation] = useState(false);
  const [editConsultation, setEditConsultation] = useState(false);
  const [search, setSearch] = useState('');

  const { patients } = useSelector((store) => store.patientData);
  const userId = useSelector((store) => store.userData?.user?._id);
  const { organization, tenant, tenantLogo } = useSelector(
    (store) => store.tenantData
  );

  useEffect(() => {
    const getData = async () => {
      let token = await getAccessTokenApi();
      getConsultationApi(patientId, order, token)
        .then((response) => {
          setConsultations(response.consultations);
          setNewConsultation(false);
          setEditConsultation(false);
          response.consultations.length > 0
            ? setData({
                _id: response.consultations[0]._id,
                description: response.consultations[0].description,
                user: response.consultations[0].user,
                date: response.consultations[0].date,
                title: response.consultations[0].title,
              })
            : setData();
        })
        .catch((err) => {});

      setReload(false);
    };
    getData();
  }, [reload, order]);

  useEffect(() => {
    const getData = async () => {
      if (visible && !editVisible) {
        let token = await getAccessTokenApi();
        getNotePatientApi(token, patientId)
          .then((response) => {
            setNote(response.note);
          })
          .catch((err) => {});
      }
    };
    getData();
  }, [editVisible]);

  useEffect(() => {
    setPatient(
      patients.find((patient) => {
        return patient._id === patientId;
      })
    );
  }, [patientId]);

  const viewConsultation = (consultation) => {
    setEditConsultation(false);
    dataAll && setDataAll(null);

    setData({
      _id: consultation._id,
      description: consultation.description,
      user: consultation.user,
      date: consultation.date,
      patientId: consultation.patient,
      title: consultation.title,
    });
    window.scroll({
      top: '500',
      behavior: 'instant',
    });
  };

  const viewAll = () => {
    const data = [];

    consultations &&
      consultations.map((consultation) =>
        data.push({
          description: consultation.description,
          user: consultation.user,
          date: consultation.date,
          title: consultation.title,
        })
      );
    setData(null);
    setDataAll(data);
    window.scroll({
      top: '500',
      behavior: 'instant',
    });
  };

  let consultationFilters = [];

  if (search) {
    consultationFilters = consultations.filter((consultation) =>
      consultation?.title
        ?.toLowerCase()
        .trim()
        .includes(search?.toLowerCase().trim())
    );
  }

  const deleteConsultation = () => {
    ModalAntd.confirm({
      title: 'Eliminar Consulta',
      content: `¿ Estas seguro que quieres eliminar el parte del ${moment(
        data.date
      ).format('DD-MM HH:mm')} realizada por ${data.user.name} ${
        data.user.lastname
      }?`,
      okText: 'Aceptar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        const token = await getAccessTokenApi();
        deleteConsultationApi(data._id, token)
          .then((response) => {
            if (response?.code !== 200) {
              notification['error']({ message: response.message });
            } else {
              notification['success']({ message: response.message });
              setReload(true);
            }
          })
          .catch(() => {
            notification['error']({
              message: 'Error del servidor, intente mas tarde.',
            });
          });
      },
    });
  };

  const printConsultation = async () => {
    const getProps = async () => {
      try {
        const token = getAccessTokenApi();
        let consultations = dataAll ? dataAll : [data];
        let signatures = Array(consultations?.length);
        await Promise.all(
          consultations?.map(async (data, index) => {
            data.user?.signature &&
              (await getSignatureApi(data.user.signature, token)
                .then((response) => {
                  if (response?.status !== 200) {
                    signatures[index] = null;
                  } else {
                    signatures[index] = response.url;
                  }
                })
                .catch((err) => console.log(err)));
          })
        );

        return {
          dataConsultations: { consultations, signatures: signatures },
          organization,
          tenant,
          patient,
          title: 'Detalle consulta',
          tenantLogo,
        };
      } catch {
        message.error('Error al generar documento. Intente nuevamente');
      }
    };

    message.loading(
      'Generando documento. Por favor espere... Esta acción puede tardar...',
      0
    );
    const props = await getProps();

    const doc = <PdfConsultation {...props} />;

    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `HC Ambulatoria - ${patientName}`);
    message
      .success('El documento se ha generado con exito.', 1)
      .then(() => message.destroy());
  };

  if (!consultations) {
    return <Spin />;
  }

  return (
    <div className="evolution">
      <div className="evolution-header">
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title={`${patientName} (${moment().diff(
            patient.birthDate,
            'years'
          )} años)`}
          style={{ textTransform: 'capitalize' }}
          extra={
            <>
              <Tooltip
                title={visible ? 'Ocultar Mis Notas' : 'Mostrar Mis Notas'}
              >
                <Button
                  type="link"
                  onClick={async () => {
                    let token = await getAccessTokenApi();
                    visible
                      ? setVisible(false)
                      : await getNotePatientApi(token, patientId)
                          .then((response) => {
                            setNote(response.note);
                          })
                          .then(() => {
                            setVisible(true);
                          })
                          .catch((err) => {});
                  }}
                >
                  {visible ? <CloseCircleFilled /> : <FormOutlined />}
                </Button>
              </Tooltip>
            </>
          }
        >
          {visible && (
            <Row gutter={24}>
              <Col xs={4} md={3} lg={2} xl={1}>
                <Button
                  type="link"
                  onClick={() => {
                    setEditVisible(!editVisible);
                  }}
                >
                  {editVisible ? <CloseOutlined /> : <EditOutlined />}
                </Button>
              </Col>
              {!editVisible ? (
                <Col xs={20} md={21} lg={22} xl={23}>
                  <p>{note?.description || 'No hay notas...'}</p>
                </Col>
              ) : (
                <Col xs={20} md={21} lg={22} xl={23}>
                  <ConsultationForm
                    patientId={patientId}
                    setIsVisibleModal={setEditVisible}
                    setReload={setReload}
                    note={true}
                    descriptionNote={note?.description}
                    noteId={note?._id}
                  />
                </Col>
              )}
            </Row>
          )}
          <div className="description">
            <PatientDescription patient={patient} />
          </div>
        </PageHeader>
      </div>

      <PageListAndView
        datesTitle="Consultas"
        order={order}
        setOrder={setOrder}
        viewAll={viewAll}
        setSearch={setSearch}
        componentDate={
          <ListDates
            data={search !== '' ? consultationFilters : consultations}
            viewData={viewConsultation}
          />
        }
        detailTitle="Detalle Consulta"
        data={data}
        dataAll={
          dataAll && (
            <div className="view-info">
              {newConsultation && (
                <ConsultationForm
                  patientId={patientId}
                  setReload={setReload}
                  turnId={turnId}
                  shiftId={shiftId}
                  turnDay={turnDay}
                />
              )}
              {dataAll?.map((data, index) => (
                <ViewInfo data={data} key={index} />
              ))}
            </div>
          )
        }
        componentDetail={
          <div>
            {(newConsultation || editConsultation) && (
              <ConsultationForm
                patientId={patientId}
                setReload={setReload}
                editConsultation={editConsultation}
                data={editConsultation && data}
                turnId={turnId}
                shiftId={shiftId}
                turnDay={turnDay}
              />
            )}
            {!editConsultation && <ViewInfo data={data} />}
          </div>
        }
        extra={
          <>
            <PermissionsAsigner requiredRole={ROLES.OWNER}>
              <Tooltip title="Eliminar Consulta">
                <Button
                  type="link"
                  onClick={() => deleteConsultation()}
                  disabled={!data || newConsultation || editConsultation}
                >
                  <DeleteOutlined style={data && { color: 'red' }} />
                </Button>
              </Tooltip>
            </PermissionsAsigner>
            <Tooltip title="Imprimir H.C Ambulatoria">
              <Button
                type="link"
                onClick={() => {
                  printConsultation();
                }}
                disabled={!data && !dataAll}
              >
                <PrinterOutlined />
              </Button>
            </Tooltip>
            {!dataAll &&
              data?.user?._id === userId &&
              moment().diff(moment(data?.date), 'minutes') < 240 && (
                <Tooltip
                  title={
                    editConsultation ? 'Deshaer edicion' : 'Editar Consulta'
                  }
                >
                  <Button
                    type="link"
                    onClick={() => setEditConsultation(!editConsultation)}
                    disabled={newConsultation}
                  >
                    {editConsultation ? (
                      <MinusCircleOutlined />
                    ) : (
                      <EditOutlined />
                    )}
                  </Button>
                </Tooltip>
              )}
            <Tooltip
              title={newConsultation ? 'Deshacer Consulta' : 'Nueva Consulta'}
            >
              <Button
                type="link"
                onClick={() => setNewConsultation(!newConsultation)}
                disabled={editConsultation}
              >
                {newConsultation ? <MinusCircleOutlined /> : <PlusOutlined />}
              </Button>
            </Tooltip>
          </>
        }
      />
    </div>
  );
}
