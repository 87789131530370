import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styleBody } from './styles';
import moment from 'moment';

export function PdfPatientData({ patient }) {
  return (
    <View>
      <View style={styleBody.dataEvolution}>
        <Text style={styleBody.subtitle}>Identificación paciente</Text>
        <View style={styleBody.data}>
          <Text style={styleBody.textTitle}>Apellido y nombre:</Text>
          <Text style={[styleBody.text, { textTransform: 'capitalize' }]}>
            {patient.name}
            {'  '}
          </Text>
          <Text style={styleBody.textTitle}>DNI:</Text>
          <Text style={styleBody.text}>{patient.dni}</Text>
        </View>
        <View style={styleBody.data}>
          <Text style={styleBody.textTitle}>Obra social / prepaga:</Text>
          <Text style={styleBody.text}>
            {patient.socialWork}
            {'  '}
          </Text>
          <View style={styleBody.data}>
            <Text style={styleBody.textTitle}> Nro afiliado:</Text>
            <Text style={styleBody.text}>{patient.affiliate}</Text>
          </View>
        </View>
        <View style={styleBody.data}>
          <Text style={styleBody.textTitle}>Fecha de nacimiento:</Text>
          <Text style={styleBody.text}>
            {moment(patient.birthDate).format('DD/MM/YYYY')}
            {'  '}
          </Text>
          <Text style={styleBody.textTitle}> Edad:</Text>
          <Text style={styleBody.text}>
            {moment().diff(patient.birthDate, 'years')} años
          </Text>
        </View>
      </View>
    </View>
  );
}
